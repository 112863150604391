.str-chat__channel-list-messenger__main,
.str-chat__channel-list-messenger-react__main,.str-chat__container  {
    overflow-y: scroll !important; /* Enable vertical scroll */
}

/* For WebKit browsers (Chrome, Safari) */
.str-chat__channel-list-messenger__main::-webkit-scrollbar,
.str-chat__channel-list-messenger-react__main::-webkit-scrollbar {
    width: 8px; /* Width of the scrollbar */
}

/* Track */
.str-chat__channel-list-messenger__main::-webkit-scrollbar-track,
.str-chat__channel-list-messenger-react__main::-webkit-scrollbar-track,.str-chat__container  {
    background: transparent; /* Track color */
}

/* Handle */
.str-chat__channel-list-messenger__main::-webkit-scrollbar-thumb,
.str-chat__channel-list-messenger-react__channel-list-messenger-react__main::-webkit-scrollbar-thumb,.str-chat__container  {
    background: #00b8d9; /* Scrollbar color */
    border-radius: 10px; /* Optional: Rounded corners */
}

/* Handle on hover */
.str-chat__channel-list-messenger__main::-webkit-scrollbar-thumb:hover,
.str-chat__channel-list-messenger-react__main::-webkit-scrollbar-thumb:hover,.str-chat__container  {
    background: #00b8d9; /* Darker shade on hover */
}

.str-chat__main-panel-inner {
    overflow-y: scroll !important; /* Enable vertical scroll */
}

/* For WebKit browsers (Chrome, Safari) */
.str-chat__main-panel-inner::-webkit-scrollbar {
    width: 8px; /* Width of the scrollbar */
}

/* Track */
.str-chat__main-panel-inner::-webkit-scrollbar-track {
    background: transparent; /* Track color */
}

/* Handle */
.str-chat__main-panel-inner::-webkit-scrollbar-thumb {
    background: #00b8d9; /* Scrollbar color */
    border-radius: 10px; /* Optional: Rounded corners */
}

/* Handle on hover */
.str-chat__main-panel-inner::-webkit-scrollbar-thumb:hover {
    background: #00a1b3; /* Darker shade on hover */
}

.str-chat__message-list-scroll {
    overflow-y: scroll !important; /* Enable vertical scroll */
}

/* For WebKit browsers (Chrome, Safari) */
.str-chat__message-list-scroll::-webkit-scrollbar {
    width: 8px; /* Width of the scrollbar */
}

/* Track */
.str-chat__message-list-scroll::-webkit-scrollbar-track {
    background: transparent; /* Track color */
}

/* Handle */
.str-chat__message-list-scroll::-webkit-scrollbar-thumb {
    background: #00b8d9; /* Scrollbar color */
    border-radius: 10px; /* Optional: Rounded corners */
}

/* Handle on hover */
.str-chat__message-list-scroll::-webkit-scrollbar-thumb:hover {
    background: #00a1b3; /* Darker shade on hover */
}
.str-chat__main-panel-inner {
    overflow-y: scroll !important; /* Enable vertical scroll */
}

/* For WebKit browsers (Chrome, Safari) */
.str-chat__main-panel-inner::-webkit-scrollbar {
    width: 8px; /* Width of the scrollbar */
}

/* Track */
.str-chat__main-panel-inner::-webkit-scrollbar-track {
    background: transparent; /* Track color */
}

/* Handle */
.str-chat__main-panel-inner::-webkit-scrollbar-thumb {
    background: #00b8d9; /* Scrollbar color */
    border-radius: 10px; /* Optional: Rounded corners */
}

/* Handle on hover */
.str-chat__main-panel-inner::-webkit-scrollbar-thumb:hover {
    background: #00a1b3; /* Darker shade on hover */
}


/* General Animation for form appearance */
@keyframes slideInFromLeft {
  0% {
    opacity: 0;
    transform: translateX(-100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slideInFromRight {
  0% {
    opacity: 0;
    transform: translateX(100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

/* Use this class to trigger animation from left */
.animate-from-left {
  animation: slideInFromLeft 0.6s ease-out;
}

/* Use this class to trigger animation from right */
.animate-from-right {
  animation: slideInFromRight 0.6s ease-out;
}
