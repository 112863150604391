.loader-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh; /* Full screen height */
  background-color: #0d0d0d; /* Full dark background */
}

.spinner {
  border: 4px solid rgba(0, 184, 217, 0.3); /* Lighter accent shade */
  border-top: 4px solid #00b8d9; /* Accent color for the spinner */
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loader-container p {
  color: #00b8d9; /* Accent color for text */
  margin-top: 10px;
  font-size: 16px;
  font-family: 'Arial', sans-serif;
}
